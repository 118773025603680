// --------

$theme-white: #D6D5C9;
$theme-black: #0a100d;
$theme-grey: #B9BAA3;
$theme-blue: #6699CC;
$theme-red: #e94f37;
$theme-yellow: yellow;

$theme-check-dark: $theme-grey;
$theme-check-light: $theme-white;
$theme-check-piece: $theme-black;

@font-face {
  font-family: cheq;
  src: url('./fonts/CHEQ_TT.TTF');
}

// --------

body {

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	margin: 0;
	padding: 0;
	height: 100vh;
	box-sizing: border-box;
	overflow-x: hidden;

	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $theme-grey;
	color: $theme-black;

	div#root {

		box-sizing: border-box;
		position: absolute;
		height: 100vh;
		width: 100vw;
		top: 0px;
		left: 0px;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		margin: 0;
		padding: 0;
		box-sizing: border-box;
		overflow-x: hidden;

	}

	*::-webkit-scrollbar { width: 7px; }
	*::-webkit-scrollbar-track { background-color: transparent; }
	*::-webkit-scrollbar-thumb {
		background-color: fade-out($theme-black, 0.75);
		border-radius: 6px;
		outline: none;

		&:hover {
			background-color: fade-out($theme-black, 0.35);
		}

	}

}

// --------

@media all and (orientation: landscape) {

	div#game {
		flex-direction: row;

		& > div#main-panel {
			width: 100vh;
			height: 100vh;
			
			div#chess-board > div { font-size: 9.25vh; }
		}
	}
}

@media all and (orientation: portrait) {

	div#game {
		flex-direction: column;
	
		& > div#main-panel {
			width: 100vw;
			height: 100vw;
			
			div#chess-board > div { font-size: 8vw; }
		}

		& > div#side-panel {
			padding: 0 65px!important;
			& > div.player-bar:first-child { display: none; }
		}
	}
}

div#game {

	position: relative;
	display: flex;

	box-sizing: border-box;
	height: 100vh;
	width: 100vw;
	margin: 0px;
	padding: 0px;	

	& > div#main-panel {

		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-direction: column;

		& > div.player-bar {
			
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			box-sizing: border-box;
			width: calc(100% - 130px);
			height: 65px;
			margin: 0 65px;

			& > div.timer {

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				font-size: 24px;
				font-weight: bold;
				color: $theme-black;
				opacity: 0.35;

				cursor: default;
				user-select: none;

				box-sizing: border-box;
				padding: 5px;
				width: 150px;
				border-radius: 5px;

			}

			&.active > div.timer { 
				opacity: 1; 
				background-color: $theme-white;
				box-shadow: 0 0 15px rgba(0,0,0,0.05);
			}

			&.danger > div.timer { 
				color: $theme-red; 
			}
			
		}

		& > div#board-container {

			position: relative;
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			box-sizing: border-box;

			& > div.captured-pieces {

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				position: relative;
				box-sizing: border-box;

				width: 65px;
				height: 100%;
				overflow: hidden;

				user-select: none;
				cursor: default;

				& > div {

					box-sizing: border-box;
					padding: 3px 0;

					font-size: 35px;
					font-family: cheq;
					color: $theme-check-piece;
					opacity: 0.5;

				}

			}

			& > div#chess-board {

				box-sizing: border-box;
				padding: 10px;
				margin: 0;

				display: grid;
				flex-grow: 1;
				grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;	

				background-color: $theme-white;
				box-shadow: 0 0 15px rgba(0,0,0,0.05);
				cursor: default;
				user-select: none;

				& > div {
					position: relative;
					box-sizing: border-box;
					overflow: hidden;	

					&.type-1 { 
						background-color: $theme-check-dark;	
					}
					&.type-2 { 
						background-color: $theme-check-light; 
						border: 1px solid $theme-grey;
					}

					& > div.content {
						box-sizing: border-box;
						position: absolute;

						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						padding: 0px;
						margin: 0px;

						display: flex;
						justify-content: center;
						align-items: center;

						font-family: cheq;
						color: $theme-check-piece;
						text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
						text-overflow: nowrap;

						z-index: 5;
						cursor: grab;
					}

					& > div.box {
						display: none;
						box-sizing: border-box;
						position: absolute;

						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						padding: 0px;
						margin: 0px;
					}

					& > div.dot {
						display: none;
						box-sizing: border-box;
						position: absolute;

						top: 37.5%;
						left: 37.5%;
						width: 25%;
						height: 25%;

						background-color: rgba(0,0,0,0.075);
						border-radius: 40px;
						padding: 0px;
						margin: 0px;
					}

					&.opponent {
						& > div.dot {
							top: 0%;
							left: 0%;
							width: 100%;
							height: 100%;

							background-color: transparent;
							border: 8px solid rgba(0,0,0,0.065);
							border-radius: 100%;
						}
					}

					&.valid {
						& > div.dot { display: block; }
					}

					&.yellow {
						& > div.box { 
							display: block;
							background-color: rgba($theme-yellow, 0.25);
						}
					}

					&.blue {
						& > div.box {
							display: block;
							background-color: rgba($theme-blue, 0.25);
						}
					}

					&.red, &.blue.red {
						& > div.box { 
							display: block;
							background-color: rgba($theme-red, 0.25);
						}
					}

					&.check {
						& > div.content {
							color: red;
						}
					}
					
				}

			}

		}

	}

	& > div#side-panel {

		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding-right: 25px;

		& > div.player-bar {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			width: 100%;
			height: 65px;

			& > div {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				font-size: 12px;
				color: rgba($theme-black, 0.5);
				font-weight: 500;

				background-color: $theme-white;
				border-radius: 5px;
				box-shadow: 0 0 15px rgba(0,0,0,0.05);

				box-sizing: border-box;
				padding: 10px 12px;

				cursor: pointer;
				user-select: none;

				&:hover { 
					//color: $theme-blue; 
					opacity: 0.8;
				}
				&.red:hover { 
					//color: $theme-red; 
				}
				
				& > i { 
					position: relative;
					top: 1px;

					font-size: 16px; 
					margin-right: 5px;
				}

				&.icon-right {
					& > i { 
						margin-right: 0px;
						margin-left: 5px;
					}
				}
			}

		}

		& > div#activity {

			display: flex;
			flex-direction: column;
			flex-grow: 1;

			position: relative;
			box-sizing: border-box;
			//margin-bottom: 10px;
			padding: 10px;
			overflow-y: auto;

			background-color: $theme-white;
			border-bottom: 1px solid rgba($theme-grey, 0.5);
			box-shadow: 0 0 15px rgba(0,0,0,0.05);

			& > div.msg {
				position: relative;
				box-sizing: border-box;
				margin: 5px 0;
				padding: 5px 10px;
				border-radius: 5px;

				width: 60%;
				min-width: 100px;

				background-color: rgba(white, 0.25);
				border: 1px solid rgba(white, 0.1);

				&.self { 
					align-self: flex-end;
				}

				& > div.label {
					font-size: 10px;
					opacity: 0.5;
					margin-bottom: 2px;
				}
			}

			& > div.move {

				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				//align-self: center;
				width: 150px;

				position: relative;
				box-sizing: border-box;
				margin: 5px 0;
				padding: 2px;
				border-radius: 5px;

				background-color: rgba($theme-black, 0.05);
				border: 1px solid rgba($theme-black, 0.05);

				&.self { 
					align-self: flex-end;
				}
				
				& > div {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				& > div.piece, & > div.captured {
					font-family: cheq;
					font-size: 18px;
					padding: 0 5px;
				}

				& > div.from, & > div.to {
					font-size: 10px;
					padding: 0 5px;
					text-transform: capitalize;
				}

				& > i {
					font-size: 20px;	
				}

			}

			& > div.event {

				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				align-self: center;

				position: relative;
				box-sizing: border-box;
				margin: 5px 0;
				border-radius: 5px;

				opacity: 0.5;

			}

		}

		& > div#chat-input {

			box-sizing: border-box;
			height: 50px;
			padding: 10px 12px;

			background-color: rgba($theme-white, 0.9);
			box-shadow: 0 0 15px rgba(0,0,0,0.05);
			
			& > input {
				outline: none;
				background: transparent;
				border: none;

				box-sizing: border-box;
				width: 100%;
				height: 100%;
			}

		}

	}

}

div.backdrop {

	position: fixed;
	z-index: 999999;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	background-color: rgba($theme-white, 0.75);
	backdrop-filter: blur(10px);

	display: none;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;

	&.active { display: flex }

	& > div.modal {
		
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		box-sizing: border-box;
		//border: 1px solid $theme-black;
		border-radius: 25px;
		padding: 50px;
		overflow-y: auto;

		//background-color: $theme-white;
		//box-shadow: 0 0 15px rgba($theme-black, 0.15);

		max-height: 100vh;
		min-height: 250px;
		max-width: 100vw;
		min-width: 500px;

		& > div#big-icon {
			display: block;
			margin-bottom: 15px;

			background-image: url('./images/vector1.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			height: 250px;
			width: 250px;

			color: $theme-grey;
			font-size: 100px;
			font-family: cheq;

			//opacity: 0.75;
		}

		& > div#app-title {
			margin-bottom: 50px;
			font-size: 35px;
			font-weight: 500;
		}

		& > div#modal-close {
			font-size: 20px;
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
		}

	}

}

div.button, a.button {

	background-color: transparent;
	color: $theme-black;
	text-decoration: none;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;
	box-sizing: border-box;
	cursor: pointer;

	padding: 15px;
	min-width: 130px;	
	border: 1px solid $theme-black;

	font-size: 13px;
	font-weight: 500;
	transition: opacity 0.2s linear;

	&.fluid {
		min-width: 20px;
	}

	&.no-icon {
		justify-content: center;
	}

	& > i {
		position: relative;
		top: 1px;
		font-size: 15px;
		margin-right: 10px; 
	}

	& > .lds-dual-ring {
		$spinner-size: 20px;

		position: relative;
		top: 1px;
		width: $spinner-size;
		height: $spinner-size;
		margin-right: 15px; 

		@keyframes lds-dual-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	
		&:after {
			content: " ";
			display: block;
			width: $spinner-size;
			height: $spinner-size;
			border-radius: 50%;
			box-sizing: border-box;
			border: 3px solid $theme-grey;
			border-color: $theme-grey transparent $theme-grey transparent;
			animation: lds-dual-ring 1.2s linear infinite;
		}
	}	

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.7;
	}

	&.light {
		border: 0px solid transparent!important;
		font-weight: normal;
	}

	&.sub {
		background-color: transparent;
		border: 2px solid $theme-grey;
		color: $theme-grey;
	}

	&.large {
		padding: 20px;
		width: 250px;

		font-size: 16px;

		background-color: $theme-black;
		border: 2px solid $theme-black;
		border-radius: 5px;
		color: $theme-white;
	}

}

div.hide {
	display: none!important;
}